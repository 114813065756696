<script setup>
import { ref,computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const activeIndex2 = ref(route.path);

const isTransparent = computed(() => {
  return route.path === '/home' || route.path === '/NewSimilarity';
});
</script>

<template>
  <div class="header" >
    <el-menu
        :default-active="activeIndex2"
        class="el-menu-demo"
        mode="horizontal"
        :style="{ backgroundColor: isTransparent ? 'transparent' : 'rgb(224, 239, 221)'}"
        text-color="#50AE74"

        @select="handleSelect">
      <span class="spacer0"></span>
      <el-menu-item index="1">
        <img src="../assets/images/logo.png" width="50" height="50">
      </el-menu-item>
      <el-menu-item  index="2" class="title1" >
        GreenChemDB
      </el-menu-item>
      <span class="spacer"></span>
      <el-menu-item index="3" :class="{ 'active-menu-item': route.path === '/home' }">
        <router-link to="/home" class="title2">Home</router-link>
      </el-menu-item>
      <el-menu-item index="4" :class="{ 'active-menu-item': route.path === '/search' }">
        <router-link to="/search" class="title2">Search</router-link>
      </el-menu-item>
      <el-sub-menu index="5" >
          <template #title >
            <span class="title2">Browse</span>
          </template>
          <el-menu-item index="2-1" :class="{ 'active-menu-item': route.path === '/browse/usage' }">
            <router-link to="/browse/usage" class="title2">Usage</router-link>
          </el-menu-item>
          <el-menu-item index="2-2" :class="{ 'active-menu-item': route.path === '/browse/toxicityinfor'}">
            <router-link to="/browse/toxicityinfor" class="title2">Toxicity</router-link>
          </el-menu-item>
          <el-menu-item index="2-3" :class="{ 'active-menu-item': route.path === '/browse/molecular'}">
            <router-link to="/browse/molecular" class="title2">Fingerprint</router-link>
          </el-menu-item>
      </el-sub-menu>
      <el-menu-item index="6" :class="{ 'active-menu-item': route.path === '/download'}">
        <router-link to="/download" class="title2">Download</router-link>
      </el-menu-item>
      <el-menu-item index="7" :class="{ 'active-menu-item': route.path === '/help'}">
        <router-link to="/help" class="title2">Help</router-link>
      </el-menu-item>
      <el-menu-item index="8" :class="{ 'active-menu-item': route.path === '/contact'}">
        <router-link to="/contact" class="title2">Contact</router-link>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<style scoped>
.el-menu-demo, .el-menu-item, .el-sub-menu {
  border: none; /* 移除边框 */
  box-shadow: none; /* 移除阴影，如果有的话 */
}
.el-menu-demo {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%; /* 确保菜单宽度填满容器 */
  background-color: rgb(224,239,221);
  color:  #5ba449;
}
.el-menu-item:hover {
  background-color: transparent !important; /* 保持悬停时背景色不变 */
}
.active-menu-item {
  color: rgb(53,115,75) !important; /* 当前页面字体颜色变成深绿色 */
}
.title1 {
  white-space: nowrap;
  font-size: 1.5vw;
  color: #50AE74 !important;
  font-weight: bold;
  font-family: "Times New Roman", Microsoft YaHei-Bold;
}
.title2{
  font-size: 1.2vw;
  font-weight: 400;
  color: #50AE74;
  text-decoration: none;
  font-family: "Times New Roman", Microsoft YaHei-Bold;
}

.spacer {
  flex-grow: 0.8; /* 使标题占据多余空间，推动右侧元素到右边 */
}
.spacer0{
  flex-grow: 0.1;
}
</style>
